<template>
  <li class="nav-item nav-search">
    <b-nav-item
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </b-nav-item>

    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <b-spinner
          v-if="querying"
          small
        />
        <feather-icon
          v-else
          size="17"
          icon="SearchIcon"
        />
      </div>
      <b-form-input
        v-if="showSearchBar"
        v-model="query"
        placeholder="ID ou Email Principal do usuário"
        autofocus
        autocomplete="off"
        @keyup.esc="resetSearchQuery()"
        @keyup.enter="selectResult()"
      />
      <div
        class="search-input-close"
        @click="resetSearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>
    </div>
  </li>
</template>

<script>
import {
  BFormInput,
  BSpinner,
  BNavItem,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import UserService from '@/services/userService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BSpinner,
    BNavItem,
  },
  data() {
    return {
      showSearchBar: false,
      query: '',
      querying: false,
    }
  },
  watch: {
    query(query) {
      store.commit('app/TOGGLE_OVERLAY', Boolean(query))
    },
  },
  methods: {
    selectResult() {
      if (this.querying) return

      if (/^\d+$/.test(this.query)) {
        if (this.query.length > 6) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: 'ID inválido',
            },
          })

          return
        }

        router.push({ name: 'cms-user-form-update', params: { id: this.query } })
        this.resetSearchQuery()
        return
      }

      this.querying = true

      UserService.quickSearch({
        q: this.query,
      }).then(r => {
        if (r.data?.data?.user?.id) {
          router.push({ name: 'cms-user-form-update', params: { id: r.data.data.user.id } })
          this.resetSearchQuery()

          return
        }

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ops!',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Usuário não encontrado para este email',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: error.response.data.message,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.querying = false
      })
    },

    resetSearchQuery() {
      this.query = ''
      this.showSearchBar = false
    },
  },
}
</script>
