// import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'

export default [
  {
    header: 'Cms',
  },
  {
    title: 'Dashboard',
    route: 'cms-dashboard',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Dashboard',
  },
  {
    title: 'Kanban',
    route: 'cms-kanban',
    icon: 'CheckSquareIcon',
    action: 'read',
    resource: 'Kanban',
  },
  {
    title: 'Conversas',
    route: 'cms-conversations',
    icon: 'MailIcon',
    action: 'read',
    resource: 'Conversations',
  },
  {
    title: 'Usuários',
    route: 'cms-user',
    icon: 'UserIcon',
    action: 'read',
    resource: 'Users',
  },
  {
    title: 'Informativos',
    route: 'cms-informative',
    icon: 'FileIcon',
    action: 'read',
    resource: 'Informative',
  },
  {
    title: 'Planejador',
    icon: 'CompassIcon',
    children: [
      {
        title: 'Conteúdos',
        route: 'cms-planner-contents-list',
        icon: 'FileTextIcon',
        action: 'edit',
        resource: 'Planner',
      },
    ],
  },
  {
    title: 'Relatórios',
    icon: 'TrendingUpIcon',
    children: [
      {
        title: 'Vendas',
        route: 'cms-financial',
        icon: 'TrendingUpIcon',
        action: 'read',
        resource: 'Financial',
      },
      {
        title: 'Clientes recorrentes',
        route: 'cms-recurrence-clients',
        icon: 'TrendingUpIcon',
        action: 'recurrences',
        resource: 'Reports',
      },
      {
        title: 'Clientes na carteira',
        route: 'cms-total-clients',
        icon: 'TrendingUpIcon',
        action: 'total-clients',
        resource: 'Reports',
      },
      {
        title: 'Health Scores',
        route: 'cms-health-score',
        icon: 'ActivityIcon',
        action: 'healthScore',
        resource: 'Tools',
      },
      {
        title: 'Revisão de ativações',
        route: 'cms-tools-activation-review',
        icon: 'UserCheckIcon',
        action: 'activation-review',
        resource: 'Tools',
      },
      {
        title: 'Revisão v2',
        route: 'cms-tools-activation-review-v2',
        icon: 'UserCheckIcon',
        action: 'activation-review',
        resource: 'Tools',
      },
      {
        title: 'Espertinhos',
        route: 'cms-tools-smarties',
        icon: 'StarIcon',
        action: 'smarties',
        resource: 'Tools',
      },
      {
        title: 'Avaliações',
        route: 'cms-reports-ratings',
        icon: 'ThumbsUpIcon',
        action: 'ratings',
        resource: 'Reports',
      },
      {
        title: 'Turo',
        route: 'cms-tools-turo',
        icon: 'OctagonIcon',
        action: 'turo',
        resource: 'Tools',
      },
      {
        title: 'Relatórios Root',
        route: 'cms-tools-smart-reports',
        icon: 'UserIcon',
        action: 'smart-reports',
        resource: 'Tools',
      },
    ],
  },
  {
    title: 'Ferramentas',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Usuários ativos',
        route: 'cms-tools-active-users',
        icon: 'UserIcon',
        action: 'active-users',
        resource: 'Tools',
      },
      {
        title: 'Acompanhamento pós Chat',
        route: 'cms-tools-chat-follow',
        icon: 'UserIcon',
        action: 'chat-follow',
        resource: 'Tools',
      },
      {
        title: 'Importação de dados',
        route: 'cms-tools-import-data',
        icon: 'PackageIcon',
        action: 'import-data',
        resource: 'Tools',
      },
      {
        title: 'Gerenciamento de metas',
        route: 'cms-tools-goal-management',
        icon: 'TrendingUpIcon',
        action: 'read',
        resource: 'Tools',
      },
      {
        title: 'Emails',
        icon: 'MailIcon',
        action: 'read',
        resource: 'Tools',
        children: [
          {
            title: 'Templates',
            route: 'tools-email-template',
            icon: 'FileIcon',
            action: 'read',
            resource: 'Tools',
          },
          {
            title: 'Envio em massa',
            route: 'cms-mass-emails',
            icon: 'PaperAirplaneIcon',
            action: 'mass-emails',
            resource: 'Tools',
          },
          {
            title: 'Enviados',
            route: 'cms-tools-email-sent',
            icon: 'ShareIcon',
            action: 'read',
            resource: 'Tools',
          },
          {
            title: 'Emails Desativados',
            route: 'cms-tools-disabled-emails',
            icon: 'ZapOffIcon',
            action: 'read',
            resource: 'Tools',
          },
        ],
      },
      {
        title: 'Denúncias',
        route: 'cms-reports',
        icon: 'FlagIcon',
        action: 'reports',
        resource: 'Tools',
      },
      {
        title: 'Migrar Histórico',
        route: 'cms-tools-migrate-messages',
        icon: 'MailIcon',
        action: 'migrate-messages',
        resource: 'Tools',
      },
      {
        title: 'Migrar Dados de Usuário',
        route: 'cms-tools-migrate-user',
        icon: 'UserIcon',
        action: 'migrate-user',
        resource: 'Tools',
      },
      {
        title: 'Análise de performance',
        route: 'cms-performance-analysis',
        icon: 'CpuIcon',
        action: 'performance-analysis',
        resource: 'Tools',
      },
    ],
  },
  {
    title: 'Projetos',
    icon: 'LayersIcon',
    children: [
      {
        title: 'Agendador',
        icon: 'ClockIcon',
        children: [
          {
            title: 'Consultores',
            route: 'agendador-user-list',
            action: 'agendador',
            resource: 'Projects',
          },
          {
            title: 'Desabilitar Datas',
            route: 'agendador-datas',
            action: 'agendador',
            resource: 'Projects',
          },
        ],
      },
    ],
  },
  {
    title: 'Deslogar',
    // href: jwtDefaultConfig.oldCms,
    // target: '_self',
    route: 'logout',
    icon: 'LogOutIcon',
    action: 'read',
    resource: 'Auth',
  },
]
